@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/typography";
@use "../../../styles/mixins";
@use "../../../styles/borders";

#portal-wrapper {
	grid-template-columns: 1fr;
	overflow: unset;
	height: auto;
	position: relative;
	width: 100%;
	display: grid;
	gap: 30px;
	padding-top: 64px;
	
	#page-content-wrapper {
		@include mixins.hide-scrollbars();
		width: 100%;
		padding: spacing.$m;
		padding-bottom: 100px;
		overflow-x: hidden;
	}

	@media screen and (min-width: 1000px){
		grid-template-columns: 248px 1fr;
		height: 100vh;
		overflow: hidden;
		padding-left: 16px;
		padding-top: 0;

		#page-content-wrapper {
			@include mixins.hide-scrollbars();
			position: relative;
			padding-top: 24px;
			padding-bottom: 100px;
			overflow-x: hidden;
		}
	}
}

.menu-wrapper {
	.modal-wrapper {
		left: 0;
		top: 0;
		width: 0;
		height: 0;
		transform: none;
		background-color: transparent;
	}
}
