@use "../../styles/colours";
@use "../../styles/spacing";

.order-list-totals {
	padding: spacing.$l;
	padding-right: spacing.$m;
	margin: spacing.$l 0 0 0;
	border-top: 1px dashed colours.$dark-green;
	border-bottom: 1px dashed colours.$dark-green;

	@media screen and (min-width: 1000px) {
		padding-right: 38px;	
	}
}