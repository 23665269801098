/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}

@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

//Base
$white:#FFF;
$black: #0B2B48;
$brand: #0d8efd;
$lm-grey: #F2F2F2;
$lm-dark-grey: #5E787A;
$dm-grey: #253A4E;
$dark-green: #5E787A;
$card-bg: #F9F9F9;
$grey: #D5DCDC;
$disabled: #e9ecef;

//State
$positive: #30c394;
$warning: #f7a43d;
$danger: #f7a43d;

// Inputs
$input-bg: #EDF2F2;
$input-focus-border: #C3C9CF;
