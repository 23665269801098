@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";

.checkbox-wrapper {
	.MuiFormLabel-root {
		font-family: typography.$font-family-primary;
		font-size: typography.$font-size-body;
		color: colours.$dark-green;
		display: inline-block;
		margin-bottom: 12px;

		&.Mui-focused {
			color: colours.$dark-green;
		}
	}

	.MuiFormControlLabel-label {
		color: colours.$black;
		font-family: typography.$font-family-primary;
		font-size: typography.$font-size-body;
	}

	.MuiCheckbox-root {
		color: colours.$dark-green;
		transition: animations.$transition-default;
		
		&.Mui-focusVisible {
			background-color: rgba(colours.$dark-green, 0.04);
		}

		&:hover {
			background-color: transparent;
			color: rgba(colours.$dark-green, 0.7);
		}

		&.Mui-checked {
			color: colours.$brand;

			&:hover {
				background-color: transparent;
				color: rgba(colours.$brand, 0.7);
			}

			&.Mui-focusVisible {
				background-color: rgba(colours.$brand, 0.04);
			}
		}
	}
}