@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/borders";

.notification-menu-wrapper-mobile {
	.modal-wrapper {
		background-color: transparent;
		top: auto;
		left: auto;
		transform: none;
	}
}

.mobile-notifications-wrapper {
	@include mixins.hide-scrollbars;
	background-color: colours.$white;
	padding-bottom: spacing.$l;
	height: 100vh;
	overflow-y: auto;
	position: fixed;
	width: 80vw;
	top: 0;
	right: 0;
	padding-top: 54px;

	.menu-header {
		position: fixed;
		width: 80vw;
		background-color: colours.$white;
		top: 0;
		right: 0;
		z-index: 1;
	}
}