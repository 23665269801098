@use "../../styles/colours";
@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/animations";

.notification-item {
	padding: spacing.$l spacing.$m;
	font-family: typography.$font-family-primary;
	color: colours.$black;
	border-bottom: 1px solid colours.$grey;
	background-color: colours.$white;
	cursor: pointer;

	&.notification-unread {
		background-color: colours.$lm-grey;

		.notification-date {
			padding-right: 14px;
			opacity: 1;

			&::before {
				display: block;
			}
		}
	}
	
	&:hover {
		background-color: rgba(colours.$lm-grey, 0.8);
	}
}

.notification-header {
	width: 100%;
	font-weight: typography.$font-weight-semi-bold;
	flex-wrap: wrap;

	@media screen and (min-width: 1000px){
		flex-wrap: nowrap;
	}
}

.notification-name {
	font-size: 14px;
	
	@media screen and (min-width: 1000px){
		flex: 1 1;
	}
}

.notification-date {
	font-size: 12px;
	opacity: 0.6;
	position: relative;
	font-weight: typography.$font-weight-semi-bold;

	&:before {
		content: "";
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background-color: colours.$brand;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		display: none;
	}
}

.notification-content {
	font-size: 14px;
	font-weight: typography.$font-weight-regular;
	width: 100%;
	display: flex;
}

