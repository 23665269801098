@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/borders";
@use "../../styles/sizing";

.order-list-item {
	@include mixins.flex-align-center;
	font-size: typography.$font-size-header-2;
	color: colours.$black;
	padding: spacing.$m;
	padding-right: spacing.$s;
	background-color: colours.$card-bg;
	border-radius: borders.$radius-default;
	gap: spacing.$s;
	border: 1px solid colours.$grey;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
}

.order-list-item-code {
	width: 150px;
}

.order-list-item-action {
	width: 50px;

	.value {
		text-transform: uppercase;
	}
}

.order-list-item-quantity {
	width: 50px;
}

.order-list-item-price {
	width: 150px;
}

.order-list-item-cost {
	width: 150px;
}

.order-list-item-remove {
	color: colours.$black;
	opacity: 0.5;
	margin-left: -8px;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}