@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";

.token-wrapper {
	@include mixins.flex-center;
	background-color: colours.$brand;
	color: colours.$white;
	width: auto;
	display: inline-flex;
	white-space: nowrap;
	padding: 0 4px;
	height: 16px;
	font-size: 12px;
	border-radius: 4px;
}
