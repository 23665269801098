@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";

.simple-table {
	font-family: typography.$font-family-primary;
	border-collapse: collapse;
	width: 100%;
	font-size: 13px;

	th {
		padding: spacing.$m;
		padding-left: 0;
		padding-top: 0;
	}

	td {
		padding: spacing.$m;
		padding-left: 0;
	}

	td, th {
		font-family: inherit;

		&:first-of-type {
			padding-left: 4px;
		}

		&:last-of-type {
			padding-right: 4px;
		}
	}

	tr {
		td {
			border-top: 1px solid colours.$grey;
		}

		&:nth-of-type(odd) {
			td {
				background-color: rgba(colours.$grey, 0.3);
			}
		}
	}
}