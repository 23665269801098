$metro-fonts: (
	Regular: 400,
	SemiBold: 600,
	Black: 900
);

@each $font-name, $font-weight in $metro-fonts {
	@font-face {
		font-family: "Metropolis";
		src: url("../resources/webfonts/metropolis/Metropolis-#{$font-name}.otf") format("otf");
		font-weight: $font-weight;
	}
}

// Family
$font-family-primary:  "Metropolis", Metropolis, "Inter", sans-serif !important;

// Sizes
$font-size-title: 30px;
$font-size-header-1: 20px;
$font-size-header-2: 16px;
$font-size-header-3: 14px;
$font-size-body: 14px;
$font-size-sub-text: 10px;

// weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// Line-height
$line-height-s: 1rem;
$line-height-m: 1.3rem;
$line-height-l: 2rem;