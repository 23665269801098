@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/borders";

.mobile-top-bar {
	padding: spacing.$m;
	background-color: colours.$white;
	box-shadow: 0 0 4px rgba(#000, 0.05);
	height: 56px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	.notification-bell-wrapper {
		position: relative;
		top: auto;
		right: auto;
	}
}