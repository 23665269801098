@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";
@import "~react-dates/lib/css/_datepicker.css";

.SingleDatePicker {
	width: 100%;

	.SingleDatePickerInput {
		width: 100%;
		background-color: transparent;
		border: none;
	}

	.DateInput {
		width: 100%;
	}
	
	.DateInput_input {
		@include mixins.input-base-styles;
	}
}

// Day blocks
.CalendarDay__default {
	font-size: typography.$font-size-body;
	color: colours.$black;
	border-radius: borders.$radius-default;
	border-color: transparent;
	background: transparent;

	&:hover {
		background-color: rgba(colours.$brand, 0.2);
		color: colours.$brand;
		border-color: transparent;
	}
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
	opacity: 0.1;
	border-color: transparent;
	background: transparent;
}

// Month headers
.CalendarMonth_caption {
	color: colours.$black;
	font-size: typography.$font-size-body;
	font-weight: typography.$font-weight-medium;
}

// Day week headers
.DayPicker_weekHeader {
	color: colours.$black;
	font-weight: typography.$font-weight-medium;
}

// Nav buttons 
.navigation-button {
	padding: spacing.$xs;
	border-radius: borders.$radius-default;
	position: absolute;
	border: none;
	top: 18px;
	line-height: .78;
	display: inline-flex;
	border: 1px solid transparent;

	svg {
		color: colours.$black;
		width: 12px;
	}

	&.navigation-button__prev {
		left: 22px;
	}

	&.navigation-button__next {
		right: 22px;
	}

	&:hover {
		background-color: rgba(colours.$brand, 0.2);
		color: colours.$brand;
	}

	&:active {
		background-color: rgba(colours.$brand, 0.2);
	}
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
	background: rgba(colours.$brand, 0.3); //background
	color: colours.$white;
	border: none !important;

	&:active, &:hover {
		background: rgba(colours.$brand, 0.5); //background
		border: 1px solid rgba(colours.$brand, 0.7);
	}
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
	color: colours.$white;
	background: colours.$brand;
	font-weight: 600;
	
	&.CalendarDay__selected_start {
		background: colours.$brand;
		border-top-left-radius: borders.$radius-default;
		border-bottom-left-radius: borders.$radius-default;
	}
	
	&.CalendarDay__selected_end {
		border-top-right-radius: borders.$radius-default;
		border-bottom-right-radius: borders.$radius-default;
	}
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
	background: rgba(colours.$brand, 0.5);
	color: colours.$white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: rgba(colours.$brand, 0.5);
	color: colours.$white;
	border: none !important;
}

// Change bg of entire calendar
.CalendarMonth, .DayPicker__withBorder, .DayPicker_focusRegion, .CalendarMonthGrid, .DateRangePicker_picker, .DayPicker__horizontal {
	background-color: colours.$white;
}

.DayPicker, .DayPicker__withBorder {
	box-shadow: 0px 2px 6px rgba(colours.$black, 0.16);
	border-radius: borders.$radius-default;
	overflow: hidden;
}

.DateRangePicker_picker {
	border-radius: borders.$radius-default;
	overflow: hidden;
}

// Triangle styles
.DateInput_fangShape {
	fill: transparent;
}

.DateInput_fangStroke {
	stroke: transparent;
}

.CalendarMonth_table {
	margin-top: 8px;
}