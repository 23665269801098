@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/borders";
@use "../../styles/sizing";

.mobile-order-list-item {
	@include mixins.flex-align-center;
	font-size: typography.$font-size-header-2;
	color: colours.$black;
	padding: 0 spacing.$s;
	gap: spacing.$s;
	background-color: colours.$card-bg;
	border-radius: borders.$radius-default;
	border: 1px solid colours.$grey;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
	height: auto;
	cursor: pointer;

	&.moli-expanded {
		background-color: colours.$input-bg;
	}

	.moli-main {
		height: 60px;
	}
	
	.moli-expanded-row {
		border-top: 1px dashed colours.$dark-green;
		padding-top: spacing.$m;
		padding-bottom: spacing.$m;
		padding-right: spacing.$xs;
	}

	.moli-expanded-label {
		font-size: 13px;
		color: colours.$dark-green;
	}
	
	.moli-expanded-value {
		font-size: 13px;
		color: colours.$black;
		font-weight: typography.$font-weight-semi-bold;
	}

	.order-list-item-code {
		font-weight: typography.$font-weight-semi-bold;
		width: 100%;
		order: 1;
	}

	.order-list-item-sub-label {
		@include mixins.truncate(100%);
		color: colours.$dark-green;
		font-weight: typography.$font-weight-medium;
		order: 2;
		width: 100%;
		font-size: 12px;
	}

	.moli-details {
		text-align: right;
		margin-left: auto;
	}

	.order-list-item-cost {
		font-size: 16px;
		font-weight: typography.$font-weight-semi-bold;
		width: auto;
	}

	.order-list-item-action {
		font-weight: typography.$font-weight-semi-bold;
		text-transform: capitalize;
		width: auto;
		font-size: 12px;
	}

	.remove-icon {
		flex-shrink: 0;
		color: colours.$dark-green;
		cursor: pointer;
	}

	.order-action-buy {
		color: colours.$positive;
	}

	.order-action-sell {
		color: colours.$danger;
	}
}
