@use "../styles/mixins";
@use "../styles/colours";
@use "../styles/spacing";
@use "../styles/animations";
@use "../styles/typography";
@use "../styles/sizing";

.text-align-left {
	text-align: left;
}

.text-align-right {
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.text-transform-capitalise {
	text-transform: capitalize;
}

.text-transform-uppercase {
	text-transform: uppercase;
}

.text-transform-lowercase {
	text-transform: lowercase;
}

.truncate {
	@include mixins.truncate(100%);
}

.text-link {
	text-decoration: none;
	display: inline;
	text-decoration: none;
	font-size: 14px;
	font-weight: typography.$font-weight-semi-bold;

	cursor: pointer;

	&:hover {
		color: colours.$brand;
	}
}

// Objects for @each loops
$sizes: 'none' 0, 'auto' auto, 'xs' spacing.$xs,'s' spacing.$s, 'm' spacing.$m, 'l' spacing.$l, 'xl' spacing.$xl, 'xxl' spacing.$xxl;
$selectors: 'm' 'margin', 'p' 'padding';
$sides: '' '', 't' '-top', 'r' '-right', 'b' '-bottom', 'l' '-left';

// Padding + margins
@each $size in $sizes {
    @each $selector in $selectors{
        @each $side in $sides {
            .#{nth($selector, 1)}#{nth($side, 1)}-#{nth($size, 1)} {
                #{nth($selector, 2)}#{nth($side, 2)}: nth($size, 2);
            }
        }
    }
}

@each $size in $sizes {
	@each $selector in $selectors {
		.#{nth($selector, 1)}v-#{nth($size, 1)} {
			#{nth($selector, 2)}-bottom: nth($size, 2); 
			#{nth($selector, 2)}-top: nth($size, 2); 
		}
		.#{nth($selector, 1)}h-#{nth($size, 1)} {
			#{nth($selector, 2)}-left : nth($size, 2); 
			#{nth($selector, 2)}-right : nth($size, 2); 
		}
	}
}

// Colours
$colours: (
    "white": colours.$white,
    "black": colours.$black,
    "brand": colours.$brand,
    "grey": colours.$grey,
    "lm-grey": colours.$lm-grey,
    "lm-dark-grey": colours.$lm-dark-grey,
    "dm-grey": colours.$dm-grey,
    "positive": colours.$positive,
    "warning": colours.$warning,
    "danger": colours.$danger
);

@each $colour, $i in $colours {
	.colour-text-#{$colour} {
		color: $i !important;
	}	
	.colour-bg-#{$colour} {
		background-color: $i !important;
	}	
}

// Animations
.transition-default {
	transition: animations.$transition-default;
}
.transition-fade-in {
	animation: animations.$transition-fade-in;
}
.transition-fade-in-slow {
	animation: animations.$transition-fade-in-slow;
}
.transition-fade-out {
	transition: animations.$transition-fade-out;
}
.transition-hover {
	transition: animations.$transition-hover;
}
.transition-fade-up {
	animation: animations.$transition-fade-up;
}
.transition-fade-up-slow {
	animation: animations.$transition-fade-up-slow;
}
.transition-fade-in-left {
	animation: animations.$transition-fade-in-left;
}
.transition-fade-in-left-slow {
	animation: animations.$transition-fade-in-left-slow;
}
.transition-fade-in-right {
	animation: animations.$transition-fade-in-right;
}
.transition-fade-in-right-slow {
	animation: animations.$transition-fade-in-right-slow;
}

.icon-size-s {
	width: sizing.$s;
	height: sizing.$s;
}
.icon-size-m {
	width: sizing.$m;
	height: sizing.$m;
}
.icon-size-l {
	width: sizing.$l;
	height: sizing.$l;
}
.icon-size-xl {
	width: sizing.$xl;
	height: sizing.$xl;
}

.text-align-left {
	text-align: left;
}
.text-align-center {
	text-align: center;
}
.text-align-right {
	text-align: right;
}

.font-weight-regular {
	font-weight: typography.$font-weight-regular;
}
.font-weight-medium {
	font-weight: typography.$font-weight-medium;
}
.font-weight-semi-bold {
	font-weight: typography.$font-weight-semi-bold;
}
.font-weight-bold {
	font-weight: typography.$font-weight-bold;
}
.font-weight-black {
	font-weight: typography.$font-weight-black;
}

// Media queries

.display-mobile-only {
	@media screen and (min-width: 1000px) {
		display: none !important;
	}
}

.display-desktop-only {
	@media screen and (max-width: 1000px) {
		display: none !important;
	}
}