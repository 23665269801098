@use "../../../../styles/colours";
@use "../../../../styles/spacing";
@use "../../../../styles/typography";
@use "../../../../styles/mixins";
@use "../../../../styles/borders";

.nav-link-button {
	border-radius: borders.$radius-default;
	font-family: typography.$font-family-primary;
	font-size: typography.$font-size-body;
	font-weight: typography.$font-weight-semi-bold;
	color: colours.$black;
	padding: 0 spacing.$m;
	cursor: pointer;
	background-color: transparent;
	height: 48px;
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;

	&:hover {
		background-color: rgba(colours.$brand, 0.1);
		color: colours.$brand;
	}

	&.active-link {
		background-color: colours.$brand;
		color: colours.$white;
	}
}