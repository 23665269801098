@use "../../styles/colours";
@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/animations";

.notification-bell-wrapper {
	cursor: pointer;
	position: absolute;
	right: spacing.$m;
	top: 20px;

	&.unread-notifications {
		&:after {
			content: "";
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background-color: colours.$brand;
			position: absolute;
			right: 1px;
			top: 1px;
		}
	}

	&.notification-menu-active {
		.notification-bell-icon {
			opacity: 0.5;
		}
	}
}

.notification-bell-icon {
	color: colours.$black;
}

.notification-menu-wrapper {
	max-width: 416px;
	padding-right: 0 !important;

	&.MuiList-root {
		padding-top: 54px;
	}

	p {
		cursor: pointer;
		margin: 0;
	}
}

.menu-header {
	padding: spacing.$m spacing.$m spacing.$m spacing.$m;
	border-bottom: 1px solid colours.$grey;

	&.desktop-notification-header {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		background-color: colours.$white;
		z-index: 1;
	}
}

#basic-menu {	
	.MuiPaper-root  {
		max-height: 70vh;
		border-radius: borders.$radius-large;
		@include mixins.hide-scrollbars;
	}
}