@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/typography";

.empty-state-message {
	color: colours.$lm-dark-grey;
	font-weight: typography.$font-weight-semi-bold;

	svg {
		width: sizing.$m;
		color: colours.$danger;
	}
}