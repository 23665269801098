@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";

.fields-wrapper {
	@include mixins.input-base-styles;
	@include mixins.flex-align-center;
	gap: spacing.$s;
	color: colours.$dark-green;
	flex-wrap: wrap;
	height: auto;
	min-height: 50px;

	.bank-name {
		@include mixins.truncate(80%);
		border-right: 1px solid colours.$grey;
		padding-right: spacing.$s;
		order: 1;
		max-width: 80%;
	}

	.bank-currency {
		order: 2;
	}

	.bank-number {
		padding-right: spacing.$s;
		order: 3;
		width: 100%;
	}

	@media screen and (min-width: 1000px) {
		.bank-currency {
			order: 3;
		}

		.bank-number {
			order: 2;
			width: auto;
			border-right: 1px solid colours.$grey;
		}
	}
}
