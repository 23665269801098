@use "../../styles/colours";
@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/sizing";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/animations";

.button-base {
	@include mixins.button-base-styles;
	
	&.button-disabled {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}

	&:hover {
		background-color: darken(colours.$brand, 10%);
		border-color: darken(colours.$brand, 10%);
	}

	&:focus {
		outline: 2px solid lighten(colours.$brand, 20%);

		&:not(:focus-visible) {
			outline: none;
		}
	}
	
	&:active {
		&:focus {
			outline: none;
		}
	}

	&.button-full-width {
		width: 100%;
	}

	// Variants
	&.variant-secondary {
		background-color: colours.$lm-grey;
		color: colours.$lm-dark-grey;
		border-color: colours.$lm-grey;

		&:focus {
			outline-color: colours.$brand;
		}

		&:hover {
			background-color: darken(colours.$lm-grey, 10%);
			border-color: darken(colours.$lm-grey, 10%);
		}
	}

	&.variant-dark {
		background-color: colours.$dark-green;
		border-color: colours.$dark-green;
		color: colours.$white;

		&:focus {
			outline-color: colours.$brand;
		}

		&:hover {
			background-color: lighten(colours.$dark-green, 15%);
			border-color: lighten(colours.$dark-green, 15%);
		}
	}

	&.variant-danger {
		background-color: colours.$danger;
		border-color: colours.$danger;
		color: colours.$white;

		&:focus {
			outline-color: colours.$danger;
		}

		&:hover {
			background-color: darken(colours.$danger, 15%);
			border-color: darken(colours.$danger, 15%);
		}
	}

	&.variant-transparent {
		background-color: transparent;
		color: colours.$black;
		border-color: transparent;

		&:focus {
			outline-color: colours.$brand;
		}

		&:hover {
			background-color: colours.$brand;
			border-color: colours.$brand;
		}
	}

	&.button-subtle {
		opacity: 0.6;

		&:hover {
			opacity: 1;
		}
	}

	&.button-loading {
		opacity: 0.5;
		pointer-events: none;	
	}

	&.size-s {
		min-width: auto;
		height: 40px;
		padding: spacing.$s spacing.$m;
	}
}