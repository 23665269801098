// Useful mixins. How to use in stylesheets: '@use mixin-name();'
@use "../styles/typography";
@use "../styles/borders";
@use "../styles/animations";
@use "../styles/colours";
@use "../styles/spacing";

// Fully center and element using flex
@mixin flex-center {
    display: flex;
	align-items: center;
	justify-content: center;
}

// Align center element using flex
@mixin flex-align-center {
    display: flex;
	align-items: center;
}

// Justify center element using flex
@mixin flex-justify-center {
    display: flex;
	justify-content: center;
}

// Add ellipses to text
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Hide scrollbars
@mixin hide-scrollbars {
	&::-webkit-scrollbar {
	  width: 0 !important
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

// Set width and height
@mixin width-and-height($dimensions) {
	width: $dimensions;
	height: $dimensions;
}

// Set element fullscreen
@mixin fullscreen($position) {
	width: 100vw;
	height: 100vh;
	position: $position;
	left: 0;
	top: 0;
}

// Remove default ul styling
@mixin ul-reset {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

// Remove default li styling
@mixin li-reset {
	margin: 0;
	padding: 0;

	&::marker {
		content: "";
	}
}

// Default input field styling
@mixin input-base-styles {
	background-color: colours.$input-bg;
	border-radius: borders.$radius-default;
	color: colours.$black;
	font-size: typography.$font-size-body;
	padding: spacing.$m;
	font-family: typography.$font-family-primary;
	font-weight: typography.$font-weight-regular;
	transition: animations.$transition-default;
	border: 2px solid colours.$input-bg;
	box-sizing: border-box;
	height: 50px;
	width: 100%;

	&::placeholder{
		color: rgba(colours.$black, 0.3);
		transition: animations.$transition-default;
	}
	
	&:hover{
		&::placeholder{
			color: rgba(colours.$black, 0.5);		
		}
	}
	
	&:focus, &:focus-within{
		border-color: colours.$input-focus-border;
		
		&::placeholder{
			color: rgba(colours.$black, 0.5);		
		}
	}

	&:focus-visible{
		outline: none;
		border-color: colours.$input-focus-border;
	}

	&:disabled {
		background-color: colours.$disabled;
		border-color: colours.$disabled;
		cursor: default;
		pointer-events: none;
	}

	&::selection {
		color: colours.$brand;
		background-color: rgba(colours.$brand, 0.2);
	}
}

@mixin button-base-styles {
	@include flex-center;
	background-color: colours.$brand;
	color: colours.$white;
	border-radius: borders.$radius-default;
	font-weight: typography.$font-weight-semi-bold;
	font-size: typography.$font-size-body;
	height: 48px;
	min-width: 120px;
	border: 1px solid colours.$brand;
	transition: animations.$transition-default;
	cursor: pointer;
	padding: 0 spacing.$l;
}