@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";

.input-helper-text {
	font-size: typography.$font-size-body;
	margin-top: spacing.$xs;
	color: colours.$black;
	animation: animations.$transition-fade-up;

	&.input-helper-text-invalid {
		color: colours.$danger;
		animation: animations.$shake;
	}
}