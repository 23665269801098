@use "../../../../styles/colours";
@use "../../../../styles/spacing";
@use "../../../../styles/typography";
@use "../../../../styles/mixins";
@use "../../../../styles/borders";

#side-bar-wrapper {
	@include mixins.hide-scrollbars;
	padding: spacing.$m;
	padding-bottom: spacing.$xl;
	background-color: colours.$white;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 80vw;
	z-index: 10;
	overflow-y: auto;

	@media screen and (min-width: 1000px) {
		background-color: colours.$card-bg;
		border: 1px solid colours.$grey;
		width: 248px;
		height: 624px;
		position: relative;
		left: auto;
		top: auto;
		border-radius: 10px;
		margin-top: 24px;
		overflow-y: unset;
	}
}