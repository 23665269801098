@use "../../../styles/borders";
@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/sizing";
@use "../../../styles/typography";
@use "../../../styles/colours";

.asset-logo-wrapper {
	@include mixins.width-and-height(sizing.$xl);
	border-radius: borders.$radius-default;
	overflow: hidden;
	position: relative;

	img {
		@include mixins.width-and-height(100%);
		transition: animations.$transition-default;
	}

	&.no-logo {
		@include mixins.flex-center;
		color: rgba(colours.$lm-dark-grey, 0.8);
		background-color: rgba(colours.$lm-dark-grey, 0.1);
		font-weight: typography.$font-weight-semi-bold;
	}
}