@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";

.toast {
	&.toast-type-positive {
		.MuiPaper-root {
			background-color: colours.$positive;
			font-family: typography.$font-family-primary;
			min-width: auto;
		}
	}
	&.toast-type-danger {
		.MuiPaper-root {
			background-color: colours.$danger;
			font-family: typography.$font-family-primary;
			min-width: auto;
		}
	}
}
