@use "../../styles/colours";
@use "../../styles/mixins";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/animations";


.date-field {
    .date-field-outer {
        @include mixins.input-base-styles();
        margin: 0;

        .calendar-trigger {
            color: colours.$black;
            margin-right: spacing.$xs;
        }
        
        .date-field-field {
            color: colours.$black;
            font-family: typography.$font-family-primary;
            
            &::placeholder{
                color: rgba(colours.$black, 0.3);
                transition: animations.$transition-default;
            }
        }
    }
    
    .react-calendar {
        background-color: #fff;
        font-family: typography.$font-family-primary;
        
        button {
            font-family: typography.$font-family-primary;
            color: colours.$black;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                background-color: rgba(colours.$brand, 0.2);
                color: colours.$brand;
            }

            &.react-calendar__tile--active {
                background-color: rgba(colours.$brand, 0.3);
                color: colours.$brand;
                font-weight: typography.$font-weight-semi-bold;
            }
        }
    }
    
    .react-calendar__navigation {
        display: flex;
        
    }
    
    .react-calendar__navigation__arrow {
        background-color: transparent;
        width: sizing.$xl;
    }
    
    .react-calendar__navigation__label {
        background-color: transparent;
        font-weight: typography.$font-weight-semi-bold;
    }
    
    .react-calendar__month-view__days {
        margin-top: 8px;
    }
    
    .react-calendar__month-view__days__day {
        font-family: typography.$font-family-primary;
    }

    .react-calendar__month-view__weekdays {
        padding-top: 16px;

        .react-calendar__month-view__weekdays__weekday {
            text-align: center;
            font-weight: typography.$font-weight-semi-bold;
        }
    }
}