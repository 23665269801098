@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/typography";
@use "../../../styles/mixins";

@mixin chart-line-legend {
	background-color: colours.$brand;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: "";
	width: 6px;
	height: 6px;
	position: absolute;
}

.recharts-surface {
	text {
		font-family: typography.$font-family-primary;
	}

	.recharts-cartesian-axis-tick {
		text {
			fill: colours.$dark-green;
			font-size: 13px;
		}
	}
}

.recharts-reference-area-rect {
	fill: rgba(colours.$brand, 0.08);
}

.recharts-cartesian-grid {
	line {
		stroke: rgba(colours.$brand, 0.2);
	}
}

.recharts-cartesian-axis-line {
	stroke: rgba(colours.$brand, 0.5);
}

.recharts-line {
	path {
		stroke: colours.$brand;
	}

	&.line-2 {
		path {
			stroke: colours.$positive;
		}
	}
}

.recharts-active-dot {
	circle {
		fill: colours.$brand;
	}
}

.recharts-tooltip-wrapper {
	background-color: colours.$white;
	box-shadow: 0 0 4px rgba(#000, 0.1);
	background-color: colours.$card-bg;
	padding: spacing.$m;
	border-radius: 4px;
	color: colours.$black;

	.valuations-graph-tooltip-label {
		padding-bottom: spacing.$s;
		font-size: 12px;
		color: colours.$black;
	}

	.valuations-graph-tooltip-value {
		margin-bottom: spacing.$s;
		font-weight: typography.$font-weight-semi-bold;
		position: relative;
		padding-left: 12px;

		&:before {
			@include chart-line-legend();
		}


		
	}

	.valuations-graph-tooltip-contribution {
		font-weight: typography.$font-weight-semi-bold;
		position: relative;
		padding-left: 12px;

		&:before {
			@include chart-line-legend();
			background-color: colours.$positive;
		}

	}
}

.recharts-active-dot {
	.dot-2 {
		fill: colours.$positive;
	}
}

