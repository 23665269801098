@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";

.bank-account-option {
	gap: 2px;

	.account-fields {
		font-size: 14px;
		width: 100%;
	}

	.account-number {
		font-size: 13px;
	}
}
