@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/typography";
@use "../../../styles/mixins";


.selection-menu-item {
	color: colours.$black;
	font-size: typography.$font-size-body;
	font-weight: typography.$font-weight-semi-bold;
	border-bottom: 2px solid colours.$brand;
	opacity: 0.5;
	padding: 0 8px 4px 8px;
	cursor: pointer;

	&:first-of-type {
		padding-left: 4px;
	}

	&:last-of-type {
		padding-right: 4px;
	}

	&.selection-menu-active-item {
		opacity: 1;
	}
}

.selection-menu {
	display: inline-flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}