@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";

.number-input-field-wrapper {
	position: relative;

	&.number-field-with-inner-label {
		input {
			padding-left: 100px;
		}
	}

	&:focus-within {
		.nfi-inner-label {
			color: rgba(colours.$black, 0.7);		
		}
	}

	&.helper-text {
		.nfi-inner-label {
			bottom: calc(spacing.$m + 21px);
		}
	}
}

.number-field-input {
	text-align: right;

	&[type="number"]::-webkit-outer-spin-button,
	&[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type="number"] {
		-moz-appearance: textfield;
	}
}

.nfi-prefix {
	display: none;
}

.nfi-inner-label {
	left: spacing.$m;
	bottom: spacing.$m;
	color: rgba(colours.$black, 0.3);
	transition: animations.$transition-default;
	position: absolute;
}