@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/spacing";
@use "../../styles/typography";

.modal-outer-wrapper {
	padding-bottom: spacing.$xl;
	overflow-y: auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;

}

.modal-wrapper {
	background-color: colours.$white;
	border-radius: borders.$radius-large;
	padding: spacing.$m;
	padding-top: spacing.$l;
	// margin: 0 auto;
	position: relative;
	width: 90vw;
	outline: none;
	margin-top: 24px;

	
	@media screen and (min-width: 1000px) {
		padding: spacing.$xl;
		max-width: 600px;
	}

	.modal-close-icon {
		color: colours.$dark-green;
		cursor: pointer;
		opacity: 0.5;
		position: absolute;
		right: 12px;
		top: 12px;

		&:hover {
			opacity: 1;
		}
	}
}