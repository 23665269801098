@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";

.password-input {
	.password-icon {
		cursor: pointer;
		opacity: 0.4;

		&:hover {
			opacity: 0.7
		}
	}

	.password-icon-hidden {
		opacity: 0.8;

		&:hover {
			opacity: 0.8
		}
	}
}