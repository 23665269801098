@use "../../styles/colours";
@use "../../styles/spacing";

.pending-transaction-header {
	font-weight: 600;
}

.expanded-row {
	td {
		&:only-of-type {
			padding: 1rem;
			background-color: #eef0f0;
		}
	}
}

.expanded-row-table {
	width: 100%;
	margin-top: 16px;
	border-collapse: collapse;

	.text-right {
		text-align: right;
	}

	th {
		padding-bottom: 8px;
		font-weight: 500;
		color: colours.$black;
		text-align: left;
	}

	td {
		border-bottom: 1px solid colours.$grey;
		background-color: transparent !important;
	}
}