@use "./styles/layout/grid";
@use "./styles/colours";
@use "./styles/spacing";
@use "./styles/typography";
@use "./styles/borders";

* {
	box-sizing: border-box;
}

.App {
	overflow-x: hidden;
}

#master-container {
	width: 100vw;
	max-width: 1080px;
	margin: 0 auto;
	overflow-x: hidden;
}

body {
	font-family: typography.$font-family-primary;
	background-color: colours.$white;
	padding: 0;
	margin: 0;
}

.title {
	font-size: typography.$font-size-title;
	font-weight: typography.$font-weight-black;
	color: colours.$brand;
	margin: 0;
}

h1 {
	font-size: typography.$font-size-header-1;
	font-weight: typography.$font-weight-black;
	color: colours.$black;
	margin: 0;
	display: inline-block;

	&.bold {
		font-weight: typography.$font-weight-bold;
	}

	&.regular {
		font-weight: typography.$font-weight-regular;
	}
}

h2 {
	font-size: typography.$font-size-header-2;
	font-weight: typography.$font-weight-bold;
	color: colours.$black;
	margin: 0;
	display: inline-block;
}

h3 {
	font-size: typography.$font-size-header-3;
	font-weight: typography.$font-weight-black;
	color: colours.$black;
	margin: 0;
	display: inline-block;
}

h4 {
	font-size: typography.$font-size-header-3;
	font-weight: typography.$font-weight-medium;
	color: colours.$black;
	margin: 0;
}

p {
	color: colours.$black;
	font-size: typography.$font-size-body;
	font-weight: typography.$font-weight-regular;
	line-height: typography.$line-height-m;
	margin: 0;
}

.sub-text {
	font-size: typography.$font-size-sub-text;
	font-weight: typography.$font-weight-regular;
	color: colours.$black;
}

.sub-label {
	font-size: 12px;
	font-weight: typography.$font-weight-regular;
	color: colours.$dark-green;
}

ul, ol {
	padding-left: 24px;
	margin: 0;
}