@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/typography";

.MuiTooltip-tooltip {
	background-color: colours.$white !important;
	color: colours.$black !important;
	font-size: typography.$font-size-body !important;
	box-shadow: 0px 2px 6px rgba(colours.$black, 0.16) !important;
}
