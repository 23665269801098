@use "../../styles/colours";
@use "../../styles/mixins";
@use "../../styles/sizing";

.custom-option-icon {
	@include mixins.width-and-height(sizing.$m);
	color: colours.$danger;
}

.custom-option-disabled {
	cursor: default;
	.custom-option-label {
		pointer-events: none;
		opacity: 0.5;
		cursor: default;
	}
}