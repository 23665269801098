@use "../../styles/colours";
@use "../../styles/spacing";

.divider {
	background-color: colours.$grey;
	margin: spacing.$s 0;
	border: none;
	width: 100%;
	height: 1px;

	&.divider-vertical {
		height: auto;
		width: 1px;
		margin: 0;
	}
	
	&.divider-dashed {
		background-color: transparent;
		border: 1px dashed colours.$dark-green;

		&.colour-brand {
			background-color: transparent;
			border-color: colours.$brand;
		}
	
		&.colour-grey {
			background-color: transparent;
			border-color: colours.$grey;
		}
	
		&.colour-black {
			background-color: transparent;
			border-color: colours.$black;
		}
	
		&.colour-white {
			background-color: transparent;
			border-color: colours.$white;
		}
	
		&.colour-lm-grey {
			background-color: transparent;
			border-color: colours.$lm-grey;
		}
	
		&.colour-dm-grey {
			background-color: transparent;
			border-color: colours.$dm-grey;
		}
	
		&.colour-dark-green {
			background-color: transparent;
			border-color: colours.$dark-green;
		}
	}

	&.colour-brand {
		background-color: colours.$brand;
	}

	&.colour-grey {
		background-color: colours.$grey;
	}

	&.colour-black {
		background-color: colours.$black;
	}

	&.colour-white {
		background-color: colours.$white;
	}

	&.colour-lm-grey {
		background-color: colours.$lm-grey;
	}

	&.colour-dm-grey {
		background-color: colours.$dm-grey;
	}

	&.colour-dark-green {
		background-color: colours.$dark-green;
	}

	&.spacing-none {
		margin: 0;
	}

	&.spacing-m {
		margin: spacing.$m 0;
	}

	&.spacing-l {
		margin: spacing.$l 0;
	}

	&.spacing-xl {
		margin: spacing.$xl 0;
	}

	&.spacing-top-none {
		margin-top: 0;
	}

	&.spacing-top-s {
		margin-top: spacing.$s;
	}

	&.spacing-top-m {
		margin-top: spacing.$m;
	}

	&.spacing-top-l {
		margin-top: spacing.$l;
	}

	&.spacing-top-xl {
		margin-top: spacing.$xl;
	}

	&.spacing-bottom-none {
		margin-bottom: 0;
	}

	&.spacing-bottom-s {
		margin-bottom: spacing.$s;
	}

	&.spacing-bottom-m {
		margin-bottom: spacing.$m;
	}

	&.spacing-bottom-l {
		margin-bottom: spacing.$l;
	}

	&.spacing-bottom-xl {
		margin-bottom: spacing.$xl;
	}

}