@use "../../styles/colours";

.loading-icon {
	display: inline-block;
	position: relative;
	color: colours.$white;

	&.colour-brand {
		color: colours.$brand;  
	}
	
	&.colour-black {
		color: colours.$black;  
	}
}