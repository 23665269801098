@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";

.validation-icon {
	position: absolute;
	right: 0;
	width: 16px;
	height: 16px;
	transition: animations.$transition-default;
}

.valid-icon {
	top: 0;
	color: colours.$positive;
	animation: fadeUp 200ms ease;
}

.info-icon {
	color: colours.$brand;
	top: 0;
	animation: fadeUp 200ms ease;
}