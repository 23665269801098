@use "../../../styles/typography";
@use "../../../styles/borders";
@use "../../../styles/animations";
@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/mixins";

.asset-option-wrapper {
	@include mixins.flex-align-center;
	gap: spacing.$s;

	.asset-name {
		font-size: typography.$font-size-body;
		font-weight: typography.$font-weight-semi-bold;
	}

	.asset-code {
		font-size: 12px;
		font-weight: typography.$font-weight-regular;
	}
}