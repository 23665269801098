@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";

.text-input-wrapper {
	position: relative;

	&.input-full-width {
		width: 100%;
	}

	&.text-input-icon-active {
		.text-input {
			padding-right: spacing.$xxl;
		}
	}
}

::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.text-input {
	@include mixins.input-base-styles;
	display:flex;
	gap: 8px;
}

// Validation
.text-input-wrapper-invalid {
	.text-input {
		border-color: colours.$danger;

		&:focus{
			border-color: colours.$danger;
		}
	}
}

.text-input-prefix{
	font-size: 16px;
    transform: translateY(-1px);
	// margin: top 2px;
}

.wrapped-input{

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
  		-webkit-appearance: none;
	}
	input[type=number]{
		-moz-appearance:textfield !important;
	}

	&::placeholder{
		color: rgba(colours.$black, 0.3);
		transition: animations.$transition-default;
	}
	
	&:hover{
		&::placeholder{
			color: rgba(colours.$black, 0.5);		
		}
	}
	
	&:focus, &:focus-within{
		border-color: colours.$input-focus-border;
		
		&::placeholder{
			color: rgba(colours.$black, 0.5);		
		}
	}

	color: colours.$black;
	font-size: typography.$font-size-body;
	background: none;
    border: none;
    width: 100%;
    font-size: 16px;
	outline: none !important;
	height: 18px;
}

.text-input-wrapper-valid {
	.valid-icon {
		top: 0;
		opacity: 1;
	}
}

// Label
.label-input {
	font-family: typography.$font-family-primary;
	font-size: typography.$font-size-body;
	color: colours.$dark-green;
	display: inline-block;
	margin-bottom: 6px;
}

.text-input-icon-wrapper {
	position: absolute;
	right: spacing.$m;
	bottom: spacing.$s;

	svg {
		width: 20px;
		color: colours.$black;
	}
}

.helper-text-active {
	.text-input-icon-wrapper {
		top: 38px;
		bottom: unset;
	}
}