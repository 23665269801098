@use "../../styles/typography";
@use "../../styles/borders";
@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/sizing";

.select-input-wrapper, .single-select-input__menu-portal {
	position: relative;

	.select-input-separator {
		height: 50%;
		width: 1px;
		background-color: colours.$input-focus-border;
		border-radius: 2px;
	}

	.single-select-input__control, .search-select-field__menu {
		background-color: colours.$input-bg;
		border: 2px solid colours.$input-bg;
		box-sizing: border-box;
		border-radius: borders.$radius-default;
		transition: animations.$transition-default;
		min-height: 50px;
		width: 100%;
		cursor: pointer;
		box-shadow: none;

		&:hover {
			border-color: colours.$input-bg;

			.single-select-input__placeholder {
				color: rgba(colours.$black, 0.5);
			}
		}

		&.single-select-input__control--is-focused {
			border-color: colours.$input-focus-border;

			.single-select-input__value-container--has-value {
				.single-select-input__single-value {
					width: fit-content;
					color: colours.$brand;
					background-color: rgba(colours.$brand, 0.2);
				}
			}
		}
	}
	
	.single-select-input__value-container {
		padding: 0;
		padding-left: spacing.$m;

		.single-select-input__single-value {
			color: colours.$black;
			font-size: typography.$font-size-body;
			font-family: typography.$font-family-primary;
			margin: 0;
			padding: 0;
		}
	}
	

	.single-select-input__placeholder {
		color: rgba(colours.$black, 0.3);
		transition: animations.$transition-default;
		font-size: typography.$font-size-body;
		font-family: typography.$font-family-primary;
		margin-left: 0;
	}

	.single-select-input__input-container {
		padding: 0;
		margin: 0;
	}

	.single-select-input__indicators {
		padding-right: spacing.$s;

		svg {
			margin: 0 spacing.$s;
			@include mixins.width-and-height(20px);
			color: colours.$dark-green;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	// Invalid styling
	.select-input-invalid {
		.single-select-input__control {
			border-color: colours.$danger;
		}
	}

	// Select options menu styling
	.single-select-input__menu {
		background-color: colours.$input-bg;
		box-shadow: 0px 2px 6px rgba(colours.$black, 0.16) !important;
		border-radius: borders.$radius-default;
		border: none;

		.single-select-input__menu-list {
			padding: 6px;
		}

		.single-select-input__option {
			border-radius: 3px;
			color: colours.$black;
			font-size: typography.$font-size-body;
			font-family: typography.$font-family-primary;
			padding: 12px;
			cursor: pointer;

			&.single-select-input__option--is-selected {
				background-color: rgba(colours.$white, 0.5);
				background-color: transparent;
				font-weight: typography.$font-weight-bold;
				color: colours.$brand;
			}

			&.single-select-input__option--is-focused {
				background-color: rgba(colours.$white, 0.8);
				color: colours.$brand;
			}
		}
	}

	.single-select-input__multi-value {
		background-color: colours.$brand;
		border-radius: 4px;
		
		.single-select-input__multi-value__label {
			color: colours.$white;
		}

		.single-select-input__multi-value__remove {
			color: colours.$white;
			margin-left: 2px;
			opacity: 0.5;

			&:hover {
				opacity: 1;
				background-color: rgba(colours.$white, 0.2);
			}
		}
	}

	.single-select-input__menu-notice--no-options {
		border-radius: 3px;
		color: colours.$black;
		font-size: typography.$font-size-body;
		font-family: typography.$font-family-primary;
		padding: 12px;

		&:hover {
			cursor: pointer;
			background-color: rgba(colours.$white, 0.8);
			color: colours.$brand;
		}
		
		.no-options {
			cursor: default;
		}
	}
	.single-select-input__control--is-disabled{
		background-color: colours.$disabled;
		border-color: colours.$disabled;
		cursor: default;
		pointer-events: none;
	}
}