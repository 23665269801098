@use "../../styles/spacing";

$sizes: 'xs' spacing.$xs,'s' spacing.$s, 'm' spacing.$m, 'l' spacing.$l, 'xl' spacing.$xl, 'xxl' spacing.$xxl;

@each $size, $i in $sizes {
	.flex-wrapper-gap-#{$size} {
		gap: $i;
	}	
}
@each $size, $i in $sizes {
	.flex-wrapper-column-gap-#{$size} {
		column-gap: $i;
	}	
}
@each $size, $i in $sizes {
	.flex-wrapper-row-gap-#{$size} {
		row-gap: $i;
	}	
}