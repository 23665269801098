@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";

.portal-card-wrapper {
	background-color: colours.$card-bg;
	border: 1px solid colours.$grey;
	border-radius: borders.$radius-default;
	padding: spacing.$m;
  	width: 100%;
	outline: none;
	position: relative;
	
	&.portal-card-with-tabs {
		border-top-left-radius: 0;
		margin-top: 48px;

		.portal-card-tabs-wrapper {
			position: absolute;
			width: 100%;
			left: -1px;
			top: -48px;

			@media screen and (min-width: 1000px){
				width: auto;
			}
		}
	}
}

.portal-card-tab {
	@include mixins.button-base-styles;
	background-color: colours.$dark-green;
	border: none;
	opacity: 0.2;
	display: inline-flex;
	width: auto;
	border-radius: 0;
	width: 50%;

	@media screen and (min-width: 500px){
		width: 144px;
	}

	&:only-child {
		border-top-left-radius: borders.$radius-large;
		border-top-right-radius: borders.$radius-large;
	}

	&:first-of-type {
		border-top-left-radius: borders.$radius-large;
	}

	&:last-of-type {
		border-top-right-radius: borders.$radius-large;
	}

	&.tab-active {
		background-color: colours.$brand;
		opacity: 1;

		&:hover {
			background-color: colours.$brand;
			opacity: 1;
			color: colours.$white;
		}
	}

	&.tab-disabled {
		pointer-events: none;
		cursor: default;
	}

	&:hover {
		opacity: 1;
		background-color: rgba(colours.$brand, 0.2);
		color: colours.$brand;
	}
}